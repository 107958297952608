<template>
  <v-slide-x-transition>
    <MeineTermine />
  </v-slide-x-transition>
</template>

<script>
import MeineTermine from './User/MeineTermine/Uebersicht.vue'
import { mapGetters } from 'vuex'
import router from '../router'

export default {
  name: 'Register',

  components: {
    MeineTermine,
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
}
</script>
