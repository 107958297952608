<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="10" lg="9">
        <h2 class="font-weight-light text-uppercase">
          <v-badge color="primary" :content="termine.length">
            Meine Termine
          </v-badge>
        </h2>
      </v-col>
      <v-col v-if="false" cols="12" md="10" lg="9" class="text-right">
        <v-btn text color="primary">
          <v-icon>mdi-sort</v-icon>
          Sortierung
        </v-btn>
      </v-col>
      <v-col cols="12" md="10" lg="9">
        <v-row>
          <v-col cols="12" v-if="termine.length == 0" align-self="center" class="mt-5 text-center">
            <h2 class="text-center">
              Du hast dich bisher noch für keine Veranstaltung angemeldet.
            </h2>
            <v-btn class="mt-5" rounded color="primary" to="/user/entdecken">Jetzt Sportarten entdecken</v-btn>
          </v-col>
          <v-col cols="12" lg="6" v-for="(item, index) in termine" :key="index">
            <sportangebotcard :item="item"></sportangebotcard>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import card from '@/components/Sportangebot/card.vue'
import Termindetails from './Details.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MeineTermine',
  data() {
    return {
      selected_termin: { active: false },
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      db: 'db',
      sportarten: 'sportarten',
      sportangebote: 'sportangebote',
    }),
    termine(){
      return this.user.anmeldungen.map(item => { return item.sportangebot }) || []
    }
  },
  components: {
    'sportangebotcard': card,
  },
  methods: {
    parseDateTime(obj) {
      var datum = obj.datum.split('-')
      var uhrzeit = obj.uhrzeit.split(':')
      return (
        datum[2] +
        '.' +
        datum[1] +
        '.' +
        datum[0] +
        ' um ' +
        uhrzeit[0] +
        ':' +
        uhrzeit[1] +
        ' Uhr'
      )
    },
    parseTime(time) {
      var uhrzeit = time.split(':')
      return uhrzeit[0] + ':' + uhrzeit[1] + ' Uhr'
    },
    parseDate(date) {
      var datum = date.split('.')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
  },
}
</script>
