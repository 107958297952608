<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.smAndDown"
    v-if="offer.active"
    value="true"
    content-class="rounded-xl"
    :max-width="$vuetify.breakpoint.lgAndUp ? '50vw' : undefined"
  >
    <v-card :class="$vuetify.breakpoint.mdAndUp ? 'rounded-xl' : 'rounded-0'">
      <v-img
        height="200"
        :src="offer.fotos.length > 0 ? offer.fotos[0].img : ''"
        style="padding-top: env(safe-area-inset-top); height: calc(200px + env(safe-area-inset-top));"
        :style="$vuetify.breakpoint.smAndDown ? 'position: fixed; top: 0; z-index: 5000;' : undefined"
      >
        <v-row class="pa-4">
          <v-col cols="auto" align-self="center">
            <v-avatar tile>
              <v-img
                contain
                :src="offer.verein ? offer.verein.logo : ''"
              ></v-img>
            </v-avatar>
          </v-col>
          <v-col cols="auto" align-self="center" class="pl-0">
            <v-chip
              :color="offer.verein ? offer.verein.farben.primary : ''"
              :style="`color: ${
                offer.verein ? offer.verein.farben.secondary : '#000;'
              };`"
            >
              {{ offer.verein ? offer.verein.name : '' }}
            </v-chip>
          </v-col>
          <v-col align-self="center" class="text-right">
            <v-btn
              :color="offer.verein ? offer.verein.farben.secondary : ''"
              :style="`color: ${
                offer.verein ? offer.verein.farben.primary : '#000;'
              };`"
              fab
              small
              @click="closing()"
            >
              <font-awesome-icon icon="fa-light fa-xmark" size="2x" />
            </v-btn>
          </v-col>
        </v-row>
      </v-img>
      <v-row class="pa-4" :style="$vuetify.breakpoint.smAndDown ? 'margin-top: calc(200px + env(safe-area-inset-top)); max-width: 100vw; z-index: 1000;' : undefined">
        <v-col cols="12">
          <h2>{{ offer.name }}</h2>
          <h4 class="secondary--text" v-if="offer.sportart">
            <font-awesome-icon v-if="offer.sportart.iconset == 'Fontawesome'" :icon="offer.sportart.icon" size="xl" class="mr-1" />
            <v-icon v-if="offer.sportart.iconset == 'Material' || !offer.sportart.iconset">{{ offer.sportart ? offer.sportart.icon : '' }}</v-icon>
            {{ offer.sportart.name }}
          </h4>
        </v-col>
        <v-col cols="12" v-if="offer.wiederholung == 'Einmaliger Termin'">
          <v-chip
            :color="offer.verein ? offer.verein.farben.primary : ''"
            :style="`color: ${
              offer.verein ? offer.verein.farben.secondary : '#000;'
            };`"
          >
            <v-icon class="mr-1">mdi-calendar</v-icon>
            {{ offer.termin.datum }}
          </v-chip>
          <v-chip
            class="ml-2"
            :color="offer.verein ? offer.verein.farben.primary : ''"
            :style="`color: ${
              offer.verein ? offer.verein.farben.secondary : '#000;'
            };`"
          >
            <v-icon class="mr-1">mdi-clock</v-icon>
            {{ offer.termin.beginn }} bis {{ offer.termin.ende }}
          </v-chip>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          {{ offer.beschreibung }}
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <small class="mr-1">Alter:</small>
          <v-chip
            class="mx-1"
            small
            v-for="alter in offer.altersgruppen"
            :key="alter"
          >
            {{ alter }}
          </v-chip>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" v-if="offer.material">
          <h4 class="secondary--text mb-3">Benötigtes Equipment</h4>
          <v-row>
            <v-col
              cols="4"
              class="text-center"
              v-for="equipment in offer.material"
              :key="equipment.name"
            >
              <v-icon color="secondary" x-large>{{ equipment.icon }}</v-icon>
              <br />
              <small class="font-weight-light">{{ equipment.name }}</small>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="offer.material">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" v-if="offer.veranstaltungsort">
          <v-row>
            <v-col cols="auto" align-self="center">
              <v-btn x-large icon><v-icon>mdi-google-maps</v-icon></v-btn>
            </v-col>
            <v-col align-self="center">
              <b>{{ offer.veranstaltungsort.name }}</b>
              <br />
              {{ offer.veranstaltungsort.adresse }}
              <br />
              <span>
                {{ offer.veranstaltungsort.plz }}
                {{ offer.veranstaltungsort.ort }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="offer.veranstaltungsort">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" class="text-center" v-if="!user.anmeldungen.find(item => item.veranstaltung == offer.id)">
          <v-btn
            x-large
            rounded
            :disabled="!user.data.activated"
            :color="offer.verein ? offer.verein.farben.primary : ''"
            :style="`color: ${
              offer.verein ? offer.verein.farben.secondary : '#000;'
            };`"
            @click="anmelden()"
          >
            Jetzt anmelden
          </v-btn>
          <br>
          <br>
          <small v-if="!user.data.activated">Dein Account wurde von deinem Erziehungsberechtigten noch nicht freigeschaltet. Daher kannst du dich noch nicht für eine Veranstaltung anmelden.</small>
        </v-col>
        <v-col cols="12" class="text-center" v-if="user.anmeldungen.find(item => item.veranstaltung == offer.id)">
          <v-btn
            x-large
            rounded
            :color="offer.verein ? offer.verein.farben.primary : ''"
            :style="`color: ${
              offer.verein ? offer.verein.farben.secondary : '#000;'
            };`"
            @click="cancel()"
          >
            <font-awesome-icon class="mr-4" icon="fa-duotone fa-ban" size="2x" />Anmeldung stornieren
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { doc, collection, addDoc, updateDoc, deleteDoc } from 'firebase/firestore'

export default {
  props: {
    offer: {
      type: Object,
      default: { active: false },
    },
  },
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      db: 'db',
      sportarten: 'sportarten',
      sportangebote: 'sportangebote',
    }),
  },
  methods: {
    closing() {
      this.$emit('close')
    },
    parseDateTime(obj) {
      var datum = obj.datum.split('-')
      var uhrzeit = obj.uhrzeit.split(':')
      return (
        datum[2] +
        '.' +
        datum[1] +
        '.' +
        datum[0] +
        ' um ' +
        uhrzeit[0] +
        ':' +
        uhrzeit[1] +
        ' Uhr'
      )
    },
    parseTime(obj) {
      if (obj.uhrzeit) {
        var uhrzeit = obj.uhrzeit.split(':')
        return uhrzeit[0] + ':' + uhrzeit[1] + ' Uhr'
      }
      return ''
    },
    parseDate(obj) {
      if (obj.datum) {
        var datum = obj.datum.split('-')
        return datum[2] + '.' + datum[1] + '.' + datum[0]
      }
      return ''
    },
    cancel() {
      const anmeldung = this.user.anmeldungen.find(item => item.veranstaltung == this.offer.id)
      deleteDoc(doc(this.db, 'Anmeldungen/'+anmeldung.id)).then(() => {
        this.closing()
      })
    }
  },
}
</script>
